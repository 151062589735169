import { SERVICES_TAGS } from 'constants/servicesTags';
import initialApiService from 'services/initialApiService';

import {
  GetPropertyType,
  InviteResType,
  PropertyFullInfoTypeList,
  PropertyRequestType,
  PropertyType,
  getPropertiesReqType,
  getPropertiesResType,
  getPropertyAgenciesResType,
  uploadImageType,
  uploadResourceType,
} from 'types/property/propertyTypes';

const propertyApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getPropertyInfo: builder.query<PropertyFullInfoTypeList, string>({
      query: (type) => ({
        url: `/propertytypes/list/${type}`,
        method: 'GET',
      }),
      providesTags: [SERVICES_TAGS.PROPERTY_FULL_INFO],
    }),
    getPropertyAgencies: builder.query<getPropertyAgenciesResType, void>({
      query: () => ({
        url: `/propertyagencies`,
        method: 'GET',
      }),
    }),

    deleteAgent: builder.mutation<PropertyType, { propertyId: string; email: string }>({
      query: (body) => ({
        url: 'properties/deleteagent',
        method: 'DELETE',
        body,
      }),
    }),
    createProperty: builder.mutation<PropertyType, PropertyRequestType>({
      query: (body) => ({
        url: 'properties/create',
        method: 'POST',
        body,
      }),
    }),
    updateProperty: builder.mutation<PropertyType, PropertyRequestType>({
      query: (data) => ({
        url: `properties/${data.id}/edit`,
        method: 'PATCH',
        body: data,
      }),
    }),
    uploadPropertyImage: builder.mutation<PropertyType, uploadImageType>({
      query: (body) => ({
        url: 'properties/image',
        method: 'POST',
        body,
      }),
    }),
    uploadPropertyResource: builder.mutation<PropertyType, uploadResourceType>({
      query: (body) => ({
        url: 'properties/resource',
        method: 'POST',
        body,
      }),
    }),
    deletePropertyImage: builder.mutation<PropertyType, string>({
      query: (id) => ({
        url: `properties/deleteimage/${id}`,
        method: 'DELETE',
      }),
    }),
    deletePropertyResource: builder.mutation<PropertyType, string>({
      query: (id) => ({
        url: `properties/deleteresource/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteProperty: builder.mutation<PropertyType, string>({
      query: (id) => ({
        url: `properties/delete/${id}`,
        method: 'DELETE',
      }),
    }),
    getProperties: builder.mutation<getPropertiesResType, getPropertiesReqType>({
      query: (body) => ({
        url: 'properties/list',
        method: 'POST',
        body,
      }),
    }),
    reportProperty: builder.mutation<GetPropertyType, { id: string; comments: string }>({
      query: (body) => ({
        url: 'properties/report',
        method: 'POST',
        body,
      }),
    }),
    watchProperty: builder.mutation<getPropertiesResType, string>({
      query: (id) => ({
        url: `users/watch/${id}`,
        method: 'POST',
      }),
    }),
    unWatchProperty: builder.mutation<getPropertiesResType, string>({
      query: (id) => ({
        url: `users/unwatch/${id}`,
        method: 'DELETE',
      }),
    }),
    getWatchingProperty: builder.mutation<getPropertiesResType, void>({
      query: () => ({
        url: `users/watching`,
        method: 'GET',
      }),
    }),
    getPropertyDetail: builder.query<PropertyType, string>({
      query: (id) => ({
        url: `/properties/detail/${id}`,
        method: 'GET',
      }),
      providesTags: [SERVICES_TAGS.PROPERTY_DETAIL],
    }),
    inviteAgent: builder.mutation<
      InviteResType,
      {
        propertyId: string;
        email: string;
        agencyId: string;
      }
    >({
      query: () => ({
        url: `properties/invite`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetPropertyDetailQuery,
  useWatchPropertyMutation,
  useUnWatchPropertyMutation,
  useLazyGetPropertyAgenciesQuery,
  useInviteAgentMutation,
  useDeleteAgentMutation,
  useReportPropertyMutation,
  useLazyGetPropertyInfoQuery,
  useUploadPropertyImageMutation,
  useUploadPropertyResourceMutation,
  useDeletePropertyResourceMutation,
  useDeletePropertyImageMutation,
  useDeletePropertyMutation,
  useCreatePropertyMutation,
  useUpdatePropertyMutation,
  useGetPropertiesMutation,
  useLazyGetPropertyDetailQuery,
} = propertyApiService;
