import { FC, useCallback, useEffect, useState } from 'react';

import { Button, Col, Dropdown, MenuProps, Modal, Radio, Select, Slider, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useDebounce } from 'tools/hooks/debounce.hook';
import { Checkbox, Form, Icon, Input } from 'ui';

import { termsSelector } from 'selectors/propertySlice.selector';
import { setTerms } from 'slices/propertySlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import { TermsType } from 'types/property/propertyTypes';

import s from './NavBar.module.scss';

const Filters: FC = () => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const terms = useAppSelector(termsSelector);
  const [price, setPrice] = useState<number | number[]>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rentOption, setRentOption] = useState<string>();
  const [apartmentOption, setApartmentOption] = useState<string[] | string>();
  const [bedRoomCount, setBedRoomCount] = useState<number>();

  const [searchName, setSearchName] = useState<string>();

  const debouncedTerms = useDebounce(searchName, 500) as string;

  const updateTerms = useCallback(
    (key: keyof TermsType, value: any) => {
      dispatch(
        setTerms({
          [key]: value,
        }),
      );
    },
    [dispatch],
  );

  const onChangeComplete = (value: number | number[]) => {
    setPrice(value);
    updateTerms('price', value);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = useCallback(
    async (data) => {
      setIsModalOpen(false);
      updateTerms('moreFilters', data);
    },
    [updateTerms],
  );

  /////////////////////////////////////////////////////////////////////////////////////////////
  const formatCurrency = (value) => {
    return `€${value.toLocaleString()}`;
  };

  const getFormattedRange = (min, max) => {
    const minCurrency = min * 1000;
    const maxCurrency = max * 1000;

    return `${formatCurrency(minCurrency)} — ${formatCurrency(maxCurrency)}`;
  };

  const propertyFilter = [
    { label: 'Residential', value: 'residential' },
    { label: 'Commercial', value: 'commercial' },
  ];

  const postFilter = [
    { label: 'Rent', value: 'rent' },
    { label: 'Sale', value: 'sale' },
    { label: 'Coliving', value: 'co-living' },
  ];

  const rentFilter = [
    { label: 'FreeHold', value: 'freeHold' },
    { label: 'GroundRent', value: 'groundRent' },
  ];
  const typeRentFilter = [
    { label: 'Perpetual', value: 'perpetual' },
    { label: 'Temporary', value: 'temporary' },
  ];
  const orientationFilter = [
    { label: 'North', value: 'north' },
    { label: 'East', value: 'east' },
    { label: 'West', value: 'west' },
    { label: 'South', value: 'south' },
  ];

  const rentOptions = [
    {
      value: 'rent',
      label: (
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Icon name="pin" color="#02C29A" />
          Rent
        </div>
      ),
    },
    {
      value: 'sale',
      label: (
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Icon name="pin" color="#8E53E8" />
          Sale
        </div>
      ),
    },
    {
      value: 'share',
      label: (
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Icon name="share" />
          Share
        </div>
      ),
    },
    {
      value: 'rent_sale',
      label: (
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name="pin" color="#02C29A" />
            <Icon name="pin" color="#8E53E8" />
            <Icon name="share" />
          </div>
          Rent, Sale & Share
        </div>
      ),
    },
  ];

  const apartmentOptions = [
    {
      value: 'all',
      label: 'All Property Types',
    },
    {
      value: 'apartment',
      label: 'Apartment',
    },
    {
      value: 'house',
      label: 'House',
    },

    {
      value: 'commercial',
      label: 'Commercial',
    },

    {
      value: 'plot',
      label: 'Plot/Field',
    },
  ];

  const bedRoomOption = [
    {
      value: -1,
      label: 'Any # Bedrooms',
    },
    {
      value: 1,
      label: '1 Bedroom',
    },
    {
      value: 2,
      label: '2 Bedrooms',
    },
    {
      value: 3,
      label: '3 Bedrooms',
    },
    {
      value: 4,
      label: '4 Bedrooms',
    },
    {
      value: 5,
      label: '5 Bedrooms',
    },
  ];

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <>
          <Slider
            range
            min={0}
            max={150}
            step={1}
            defaultValue={[20, 50]}
            onChangeComplete={onChangeComplete}
          />
        </>
      ),
    },
  ];

  const handleRentOption = (value: string) => {
    setRentOption(value);
    updateTerms('rentOption', value);
  };

  const handleApartmentOption = (value: string | string[]) => {
    let updatedValue = value;

    if (Array.isArray(value)) {
      if (value.length === 0) {
        updatedValue = ['all'];
      } else if (value.includes('all') && value.length > 1) {
        updatedValue = value.filter((item) => item !== 'all');
      }
    }
    setApartmentOption(updatedValue);
    updateTerms('apartOption', updatedValue);
  };
  const handleBedroomOption = (value: number) => {
    setBedRoomCount(value);
    updateTerms('bedroomCount', value);
  };

  const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
  };
  useEffect(() => {
    if (terms) {
      setRentOption(terms.rentOption);
      setApartmentOption(terms.apartOption);
      setBedRoomCount(terms.bedroomCount);
      setPrice(terms.price);
      setSearchName(terms.searchName);
      if (form) {
        form.setFieldsValue({
          propertyType: terms.moreFilters?.propertyType,
          postType: terms.moreFilters?.postType,
          groundRent: terms.moreFilters?.groundRent,
          typeGroundRent: terms.moreFilters?.typeGroundRent,
          orientation: terms.moreFilters?.orientation,
          onPlan: terms.moreFilters?.onPlan,
          shell: terms.moreFilters?.shell,
          finished: terms.moreFilters?.finished,
        });
      }
    }
  }, [terms, form]);

  useEffect(() => {
    if (debouncedTerms?.length >= 0) {
      updateTerms('searchName', debouncedTerms);
    }
  }, [debouncedTerms, updateTerms]);
  return (
    <div>
      {location.pathname === '/dashboard' && (
        <div className={s.searchForm}>
          <Select
            className={s.rentSelect}
            defaultValue="rent_sale"
            value={rentOption}
            onChange={handleRentOption}
            options={rentOptions}></Select>
          <Select
            className={`${s.typeSelect} ${
              apartmentOption?.length === 5
                ? s.width520
                : apartmentOption?.length === 4
                ? s.width420
                : apartmentOption?.length === 3
                ? s.width320
                : apartmentOption?.length === 2
                ? s.width220
                : s.width120
            }`}
            mode="multiple"
            defaultValue="all"
            value={apartmentOption}
            onChange={handleApartmentOption}
            options={apartmentOptions}></Select>
          <Select
            className={s.bedSelect}
            defaultValue={0}
            value={bedRoomCount}
            onChange={handleBedroomOption}
            options={bedRoomOption}></Select>
          <Dropdown menu={{ items }} placement="bottom" trigger={['click']}>
            <Button className={s.dropdownButton}>
              {!price ? 'Price' : getFormattedRange(price[0], price[1])}
            </Button>
          </Dropdown>
          <Button htmlType="button" onClick={showModal} className={s.dropdownButton}>
            More Filters
          </Button>
          <Input
            autoComplete="off"
            className={s.searchInput}
            value={searchName}
            onChange={handleSearchName}
            fullWidth
            name="search"
            icon="search"
            placeholder="Street or Town"
          />
        </div>
      )}
      <Modal
        centered
        title={<div style={{ fontSize: 36, fontWeight: 700 }}>More filters</div>}
        open={isModalOpen}
        onOk={handleOk}
        footer={null}
        width={1000}
        onCancel={handleCancel}>
        <Form className={s.form} form={form} onFinish={handleSubmit} layout="vertical">
          <div className={s.filter}>
            <div className={s.modalItem}>
              <Typography style={{ fontSize: 16, width: '100%', fontWeight: 600 }}>
                Property Type
              </Typography>
              <div style={{ width: '100%' }}>
                <Form.Item name="propertyType" initialValue="residential">
                  <Radio.Group
                    className={s.filterSwitch}
                    options={propertyFilter}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </div>
            </div>
            <div className={s.modalItem}>
              <Typography style={{ fontSize: 16, width: '100%', fontWeight: 600 }}>
                Type of posting
              </Typography>
              <div style={{ width: '100%' }}>
                <Form.Item name="postType" initialValue="rent">
                  <Radio.Group
                    className={s.filterSwitch}
                    options={postFilter}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </div>
            </div>
            <div className={s.modalItem}>
              <Typography style={{ fontSize: 16, width: '100%', fontWeight: 600 }}>
                Ground Rent
              </Typography>
              <div style={{ width: '100%' }}>
                <Form.Item name="groundRent" initialValue="freeHold">
                  <Radio.Group
                    className={s.filterSwitch}
                    options={rentFilter}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </div>
            </div>
            <div className={s.modalItem}>
              <Typography style={{ fontSize: 16, width: '100%', fontWeight: 600 }}>
                Type of Ground Rent
              </Typography>
              <div style={{ width: '100%' }}>
                <Form.Item name="typeGroundRent" initialValue="perpetual">
                  <Radio.Group
                    className={s.filterSwitch}
                    options={typeRentFilter}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </div>
            </div>
            <div className={s.modalItem}>
              <Typography style={{ fontSize: 16, width: '100%', fontWeight: 600 }}>
                Orientation
              </Typography>
              <div style={{ width: '100%' }}>
                <Form.Item name="orientation" initialValue="north">
                  <Radio.Group
                    className={s.filterSwitch}
                    options={orientationFilter}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className={s.checkboxes} style={{ paddingTop: 24 }}>
            <div className={s.modalItem}>
              <Typography style={{ fontSize: 16, width: '100%', fontWeight: 600 }}>
                Finish Type
              </Typography>
              <div style={{ width: '100%' }}>
                <Col span={8}>
                  <Form.Item name="onPlan">
                    <Checkbox style={{ maxHeight: 30, width: 200 }}>
                      <span>On Plan</span>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="shell">
                    <Checkbox>Shell</Checkbox>
                  </Form.Item>
                  <Form.Item name="finished">
                    <Checkbox>Finished</Checkbox>
                  </Form.Item>
                </Col>
              </div>
            </div>
            <div className={s.modalItem}>
              <Typography style={{ fontSize: 16, width: '100%', fontWeight: 600 }}>
                Size m²
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                <Form.Item name="from">
                  <Input name="from" placeholder="From"></Input>
                </Form.Item>
                <Form.Item name="to">
                  <Input name="to" placeholder="To"></Input>
                </Form.Item>
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
              <Button className={s.cancel} onClick={handleCancel}>
                CANCEL
              </Button>
              <Button type="primary" className={s.result} htmlType="submit">
                SHOW RESULTS
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};
export default Filters;
